import { routeLinks } from "../routeLinks";

interface NavigationItems {
    icon: string;
    label: string;
    route: string;
}

export const navigationItems: Array<NavigationItems> = [
    { icon: "columns", label: "Dashboard", route: routeLinks.dashboard },
    { icon: "list-alt", label: "Categories", route: routeLinks.categories },
    { icon: "layer-group", label: "Levels", route: routeLinks.levels },
]