import * as React from "react";
import { RouteComponentProps } from "react-router";
import { routeLinks } from "./routeLinks";
import { DashboardPage } from "../dashboard/dashboardPage";
import { CategoriesPage } from "../categories/categoriesPage";
import { LevelsPage } from "../levels/levelsPage";

interface RouteItem {
    path: string;
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export const routes: Array<RouteItem> = [
    { path: routeLinks.dashboard, component: DashboardPage },
    { path: routeLinks.categories, component: CategoriesPage },
    { path: routeLinks.levels, component: LevelsPage },
];
