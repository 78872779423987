import React, { useState } from 'react';
import "./navigation.scss";
import { AppIcon } from "../../common/appIcon";
import { NavigationItem } from "./navigationItem";
import { navigationItems } from "./navigationItems";

export function Navigation() {
    const [isCollapsed, setCollapsed] = useState(true);

    function toggleNavigationBar() {
        setCollapsed(!isCollapsed);
    }

    return (<nav className={`navigation ${isCollapsed ? "collapsed" : ""}`}>
        <div className="navigation-toggle d-flex justify-content-between align-items-center">
            <div className="toggle d-flex justify-content-center align-items-center" onClick={toggleNavigationBar}>
                <AppIcon icon="bars" />
            </div>
        </div>
        {navigationItems.map((item, key) => <NavigationItem
            icon={item.icon} label={item.label} route={item.route} isCollapsed={isCollapsed} key={key} />)}
    </nav>);
}