import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Container, FormFeedback, FormGroup, FormText, Input, Row } from "reactstrap";
import logo from "../../../static/logo.png";
import { authService } from "../../../infrustructure/services/authService";

export function Login() {
    const [isAuthFailed, setIsAuthFailed] = useState<boolean>();
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    async function login() {
        setIsAuthFailed(!(await authService.signIn(email, password)));
    }

    function onEmailChanged(email: string) {
        setEmail(email);
        setIsAuthFailed(false);
    }

    function onPasswordChanged(password: string) {
        setPassword(password);
        setIsAuthFailed(false);
    }

    return (<div className="d-flex align-items-center">
        <Container>
            <Row className="justify-content-center">
                <Col xs={4}>
                    <Card className="p-10 shadow-sm">
                        <CardBody>
                            <div className="d-flex px-25 align-items-center justify-content-around">
                                <img src={logo} width={80} alt="" />
                                <h3 className="text-success">LightBox</h3>
                            </div>
                            <FormGroup className="mt-10 mb-5">
                                <FormText>Email</FormText>
                                <Input type="email" className="mt-5" value={email}
                                    onChange={e => onEmailChanged(e.target.value)} invalid={isAuthFailed} />
                                <FormFeedback>Oh noes! seems this email is invalid</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <FormText>Password</FormText>
                                <Input type="password" className="mt-5" value={password}
                                    onChange={e => onPasswordChanged(e.target.value)} invalid={isAuthFailed} />
                                <FormFeedback>Oh noes! seems this password is invalid</FormFeedback>
                            </FormGroup>
                            <Button className="w-100 mt-5" color="info" onClick={login}>Login</Button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    </div>);
}