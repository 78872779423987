import React, { useState } from 'react';
import { Category } from "../../../models/category";
import useAsyncEffect from "use-async-effect";
import { categoryRepository, levelRepository } from "../../../infrustructure/repositories/repositories";
import { Container, Row } from "reactstrap";
import { AppIcon } from "../common/appIcon";
import "./levelsPage.scss";
import { LevelModalForm } from "./levelModalForm";
import { Level } from "../../../models/level";
import { iosLevelStorage, coverStorage } from "../../../infrustructure/fileStorages/fileStorages";
import { LevelCard } from "./levelCard";

export function LevelsPage() {
    const [categories, setCategories] = useState<Array<Category>>([]);
    const [levels, setLevels] = useState<Array<Level>>([]);
    const [selectedLevel, setSelectedLevel] = useState<Level>();
    const formModalState = useState(false);

    useAsyncEffect(async () => setCategories(await categoryRepository.list()), []);
    useAsyncEffect(async () => setLevels(await levelRepository.list()), []);

    async function onDelete(level: Level) {
        setLevels(levels.filter(l => l.id !== level.id));
        await coverStorage.delete(level.coverFileId);
        await iosLevelStorage.delete(level.iosLevelFileId);
        await levelRepository.delete(level);
    }

    function onEdit(level: Level) {
        setSelectedLevel(level);
        formModalState[1](true);
    }

    function onUpdated(level: Level, isNew: boolean) {
        if (isNew) {
            setLevels([level, ...levels]);
        } else {
            const item = levels.find(c => c.id === level.id) as Level;
            item.name = level.name;
            item.categoryIds = level.categoryIds;
            item.coverFileId = level.coverFileId;
            item.iosLevelFileId = level.iosLevelFileId;
            setLevels(levels);
        }
    }

    return (<Container className="level-page">
        <div className="d-flex justify-content-between align-items-center header">
            <h3 className="py-10">Levels</h3>
            <AppIcon icon="plus-circle" onClick={() => formModalState[1](true)} />
        </div>
        <Row>
            {levels.map((l, k) =>
                <LevelCard key={k} level={l} categories={categories} onEdit={onEdit} onDelete={onDelete} />)}
        </Row>
        <LevelModalForm state={formModalState}
            categories={categories}
            level={selectedLevel}
            onUpdated={onUpdated} />
    </Container>);
}