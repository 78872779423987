import { getFirebase } from "../services/firebaseService";
import firebase from "firebase/app";
import "firebase/storage";
import { v4 as uuid } from 'uuid';

export class FileStorage {
    directory?: firebase.storage.Reference;

    constructor(directory: string) {
        getFirebase().then(app => {
            this.directory = app.storage().ref(directory);
        });
    }

    public async upload(file: File): Promise<string> {
        const id = uuid();
        await this.directory?.child(id).put(file);

        return id;
    }

    public async delete(id: string): Promise<void> {
        await this.directory?.child(id).delete();
    }

    public async getLink(id: string): Promise<string> {
        return this.directory?.child(id).getDownloadURL();
    }
}