import React, { useState } from 'react';
import { Card, CardBody, Col, Container } from "reactstrap";
import { categoryRepository } from "../../../infrustructure/repositories/repositories";
import { Category } from "../../../models/category";
import useAsyncEffect from "use-async-effect";
import { AppIcon } from "../common/appIcon";
import { CategoryModalForm } from "./categoryModalForm";
import "./categoriesPage.scss";

export function CategoriesPage() {
    const [categories, setCategories] = useState<Array<Category>>([]);
    const [selectedCategory, setSelectedCategory] = useState<Category>();
    const formModalState = useState(false);

    function sortedCategories() {
        return categories.sort((a, b) => a.order - b.order);
    }

    useAsyncEffect(async () => setCategories(await categoryRepository.list()), []);

    async function onDelete(category: Category) {
        const updatedCategories = sortedCategories().filter(c => c.id !== category.id);
        for (let i = 0; i < updatedCategories.length; i++) {
            updatedCategories[i].order = i;
        }
        setCategories(updatedCategories);
        await categoryRepository.delete(category);
        await categoryRepository.updateSeveral(updatedCategories);
    }

    function onEdit(category: Category) {
        setSelectedCategory(category);
        formModalState[1](true);
    }

    async function onUp(category: Category) {
        const previous = categories.find(c => c.order === category.order - 1) as Category;
        const current = categories.find(c => c.order === category.order) as Category;
        previous.order = previous.order + 1;
        current.order = current.order - 1;
        setCategories([...categories.filter(c => c.id !== previous.id && c.id !== current.id), previous, current]);
        await categoryRepository.updateSeveral([previous, current]);
    }

    async function onDown(category: Category) {
        const next = categories.find(c => c.order === category.order + 1) as Category;
        const current = categories.find(c => c.order === category.order) as Category;
        next.order = next.order - 1;
        current.order = current.order + 1;
        setCategories([...categories.filter(c => c.id !== next.id && c.id !== current.id), next, current]);
        await categoryRepository.updateSeveral([next, current]);
    }

    function onUpdated(category: Category, isNew: boolean) {
        if (isNew) {
            setCategories([...categories, category]);
        } else {
            const item = categories.find(c => c.id === category.id) as Category;
            item.name = category.name;
            setCategories(categories);
        }
    }

    function lastOrder() {
        return sortedCategories().slice(-1)[0]?.order;
    }

    return (<Container className="category-page">
        <div className="d-flex justify-content-between align-items-center header">
            <h3 className="py-10">Categories</h3>
            <AppIcon icon="plus-circle" onClick={() => formModalState[1](true)} />
        </div>
        <Card>
            <CardBody>
                {sortedCategories().map((c, k) => (<Col xs={4}
                    className="category-card bg-light p-5 mt-10 shadow-sm d-flex justify-content-between align-items-center"
                    key={k}>
                    {c.name}
                    <div className="controls">
                        {k !== 0 && <AppIcon icon="long-arrow-alt-up" onClick={() => onUp(c)} />}
                        {k !== lastOrder() && <AppIcon
                            className="ml-10" icon="long-arrow-alt-down" onClick={() => onDown(c)} />}
                        <AppIcon className="ml-10" icon="pen" onClick={() => onEdit(c)} />
                        <AppIcon className="ml-10" icon="trash" onClick={() => onDelete(c)} />
                    </div>
                </Col>))}
            </CardBody>
        </Card>
        <CategoryModalForm state={formModalState}
            category={selectedCategory}
            onUpdated={onUpdated}
            lastOrder={lastOrder() ?? -1} />
    </Container>);
}