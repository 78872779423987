import firebase from "firebase/app";
import "firebase/auth";
import { makeAutoObservable } from "mobx";
import { getFirebase } from "./firebaseService";

class AuthService {
    public user?: firebase.User | null;

    public constructor() {
        makeAutoObservable(this);
        getFirebase().then(app => app.auth().onAuthStateChanged(user => {
            this.user = user;
        }));
    }

    public isAuthenticated(): boolean {
        return !!this.user;
    }

    public async signIn(email: string, password: string): Promise<boolean> {
        try {
            await firebase.auth().signInWithEmailAndPassword(email, password);

            return true;
        } catch {
            return false;
        }
    }

    public signOut(): Promise<void> {
        return firebase.auth().signOut();
    }
}

export const authService = new AuthService();