import React from 'react';
import "./header.scss";
import { AppIcon } from "../../common/appIcon";
import { authService } from "../../../../infrustructure/services/authService";

export function Header() {
    return (<header className="pl-20 d-flex align-items-center justify-content-between">
        <span>LightBox admin center</span>
        <AppIcon icon="sign-out-alt" onClick={authService.signOut} />
    </header>);
}