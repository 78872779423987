import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { AppIcon } from "../common/appIcon";
import { coverStorage } from "../../../infrustructure/fileStorages/fileStorages";
import React, { useState } from "react";
import { Level } from "../../../models/level";
import { Category } from "../../../models/category";
import useAsyncEffect from "use-async-effect";

interface Props {
    level: Level;
    categories: Array<Category>;
    onEdit: (level: Level) => void;
    onDelete: (level: Level) => void;
}

export function LevelCard(props: Props) {
    const [coverSrc, setCoverSrc] = useState("");

    useAsyncEffect(async () => {
        if (!props.level) {
            return;
        }

        setCoverSrc(await coverStorage.getLink(props.level.coverFileId));
    }, [props.level.coverFileId]);

    function getCategoryName(id: string) {
        return props.categories.find(c => c.id === id)?.name;
    }

    return (<Col xs={4}>
        <Card className="level-card">
            <CardHeader className="d-flex justify-content-between">
                <span>{props.level.name}</span>
                <div className="controls">
                    <AppIcon className="ml-10" icon="pen" onClick={() => props.onEdit(props.level)} />
                    <AppIcon className="ml-10" icon="trash" onClick={() => props.onDelete(props.level)} />
                </div>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col xs={5}>
                        <div className="border border-success rounded shadow-sm p-5">
                            <img className="w-100" src={coverSrc} alt="" />
                        </div>
                    </Col>
                    <Col>
                        <b>Categories:</b>
                        <ul className="mb-0">
                            {props.level.categoryIds.map((id, k) => <li key={k}>{getCategoryName(id)}</li>)}
                        </ul>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    </Col>);
}