import React, { useEffect, useState } from "react";
import { Button, FormGroup, FormText, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { levelRepository } from "../../../infrustructure/repositories/repositories";
import { iosLevelStorage, coverStorage } from "../../../infrustructure/fileStorages/fileStorages";
import { Level } from "../../../models/level";
import Select from "react-select";
import { Category } from "../../../models/category";

interface Props {
    state: [boolean, (state: boolean) => void];
    categories: Array<Category>;
    level?: Level;
    onUpdated: (level: Level, isNew: boolean) => void;
}

export function LevelModalForm(props: Props) {
    const [state, setState] = props.state;
    const [name, setName] = useState("");
    const [categoryIds, setCategoryIds] = useState<Array<string>>([]);
    const [cover, setCover] = useState<File>();
    const [iosLevelFile, setIosLevelFile] = useState<File>();

    useEffect(() => {
        setName(props.level?.name ?? "");
        setCategoryIds(props.level?.categoryIds ?? []);
    }, [props.level]);

    function close() {
        setName("");
        setCategoryIds([]);
        setCover(undefined);
        setIosLevelFile(undefined);
        setState(false);
    }

    async function save() {
        if (props.level) {
            props.level.name = name;
            props.level.categoryIds = categoryIds;
            if (cover) {
                await coverStorage.delete(props.level.coverFileId);
                props.level.coverFileId = await coverStorage.upload(cover);
            }
            if (iosLevelFile) {
                await iosLevelStorage.delete(props.level.iosLevelFileId);
                props.level.coverFileId = await iosLevelStorage.upload(iosLevelFile);
            }
            props.onUpdated(await levelRepository.update(props.level), false);
        } else {
            const level = await levelRepository.add({
                name: name,
                categoryIds: categoryIds,
                coverFileId: await coverStorage.upload(cover as File),
                iosLevelFileId: await iosLevelStorage.upload(iosLevelFile as File),
            });
            props.onUpdated(level, true);
        }

        close();
    }

    function getCategoriesAsSelectOptions() {
        return props.categories.map(c => ({ value: c.id, label: c.name }));
    }

    function getSelectedCategoriesAsSelectOptions() {
        return props.categories.filter(c => categoryIds.includes(c.id as string)).map(c => ({
            value: c.id,
            label: c.name
        }));
    }

    return (<Modal isOpen={state} toggle={close} size="md" centered>
        <ModalHeader toggle={close}>Add level</ModalHeader>
        <ModalBody>
            <FormGroup className="m-0">
                <FormText>Name</FormText>
                <Input type="text" className="mt-5" value={name} onChange={e => setName(e.target.value)} />
            </FormGroup>
            <FormGroup className="m-0">
                <FormText>Categories</FormText>
                <Select className="mt-5" value={getSelectedCategoriesAsSelectOptions()}
                    options={getCategoriesAsSelectOptions()}
                    onChange={values => setCategoryIds(values.map(v => v.value as string))} isMulti />
            </FormGroup>
            <FormGroup className="m-0">
                <FormText>Cover</FormText>
                <Input type="file" className="mt-5" onChange={e => e.target.files && setCover(e.target.files[0])} />
            </FormGroup>
            <FormGroup className="m-0">
                <FormText>iOS level file</FormText>
                <Input type="file" className="mt-5"
                    onChange={e => e.target.files && setIosLevelFile(e.target.files[0])} />
            </FormGroup>
        </ModalBody>
        <ModalFooter className="border-0 pt-0">
            <Button className="flex-grow-1" onClick={close} color="dark" outline>Cancel</Button>
            <Button className="flex-grow-1" onClick={save} color="success">Save</Button>
        </ModalFooter>
    </Modal>);
}