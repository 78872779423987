import React, { useState } from 'react';
import "./navigation.scss";
import { AppIcon } from "../../common/appIcon";
import { useHistory } from "react-router-dom";

interface Props {
    icon: string;
    label: string;
    route: string;
    isCollapsed: boolean;
}

export function NavigationItem(props: Props) {
    const [isActive, setIsActive] = useState(window.location.pathname === props.route);
    const history = useHistory();

    history.listen(location => setIsActive(location.pathname === props.route));

    function route() {
        history.push(props.route);
    }

    return (<div className={`navigation-item d-flex align-items-center overflow-hidden ${isActive ? "active" : ""}`}
        onClick={route}>
        <div className="navigation-icon text-center flex-shrink-0">
            <AppIcon icon={props.icon} />
        </div>
        {!props.isCollapsed && <span>{props.label}</span>}
    </div>);
}