import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Header } from "./components/layout/header/header";
import { Navigation } from "./components/layout/navigation/navigation";
import { MainContent } from "./components/layout/mainContent";
import { Footer } from "./components/layout/footer/footer";
import { Login } from "./components/login/login";
import { observer } from "mobx-react"
import { authService } from "../infrustructure/services/authService";

export const App = observer(() => {
    return (<Router>
        {authService.isAuthenticated()
            ? (<div className="d-flex">
                <Navigation />
                <div className="w-100">
                    <Header />
                    <MainContent />
                    <Footer />
                </div>
            </div>)
            : (<Switch>
                <Route path="*" component={Login} />
            </Switch>)}
    </Router>);
});