import firebase from "firebase/app";

function getFirebaseConfig() {
    return {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
    };
}

const cachedValues = {
    firebaseLoader: undefined as unknown as Promise<firebase.app.App>,
    firebase: undefined as unknown as firebase.app.App,
}

async function initFirebase() {
    if (process.env.REACT_APP_IS_LOCAL) {
        return firebase.initializeApp(getFirebaseConfig());
    } else {
        const response = await fetch('/__/firebase/init.json');
        return firebase.initializeApp(await response.json());
    }
}

export async function getFirebase() {
    if (cachedValues.firebaseLoader === undefined) {
        cachedValues.firebaseLoader = initFirebase();
    }

    if (!cachedValues.firebase) {
        cachedValues.firebase = await cachedValues.firebaseLoader;
    }

    return cachedValues.firebase;
}