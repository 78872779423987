import React from 'react';

interface Props {
    icon: string;
    onClick?: () => void;
    className?: string;
}

export function AppIcon(props: Props) {
    return (<i className={`fas fa-${props.icon} ${props.className || ""}`} onClick={props.onClick} />);
}