import React from 'react';
import { routes } from "./routes";
import { Route, Switch } from "react-router-dom";
import "./mainContent.scss";

export function MainContent() {
    return (<main>
        <Switch>
            {routes.map((route, key) =>
                <Route path={route.path} component={route.component} key={key} />)}
        </Switch>
    </main>);
}