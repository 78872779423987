import React, { useEffect, useState } from "react";
import { Button, FormGroup, FormText, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Category } from "../../../models/category";
import { categoryRepository } from "../../../infrustructure/repositories/repositories";

interface Props {
    state: [boolean, (state: boolean) => void];
    category?: Category;
    onUpdated: (category: Category, isNew: boolean) => void;
    lastOrder: number;
}

export function CategoryModalForm(props: Props) {
    const [state, setState] = props.state;
    const [name, setName] = useState("");

    useEffect(() => {
        setName(props.category?.name ?? "")
    }, [props.category]);

    function close() {
        setName("");
        setState(false);
    }

    async function save() {
        if (props.category) {
            props.category.name = name;
            await categoryRepository.update(props.category);
            props.onUpdated(await categoryRepository.update(props.category), false);
        } else {
            const category = await categoryRepository.add({ name: name, order: props.lastOrder + 1 });
            props.onUpdated(category, true);
        }

        close();
    }

    return (<Modal isOpen={state} toggle={close} size="sm" centered>
        <ModalHeader toggle={close}>
            Add category
        </ModalHeader>
        <ModalBody>
            <FormGroup className="m-0">
                <FormText>Name</FormText>
                <Input type="text" className="mt-5" value={name} onChange={e => setName(e.target.value)} />
            </FormGroup>
        </ModalBody>
        <ModalFooter className="border-0 pt-0">
            <Button className="flex-grow-1" onClick={close} color="dark" outline>Cancel</Button>
            <Button className="flex-grow-1" onClick={save} color="success">Save</Button>
        </ModalFooter>
    </Modal>);
}