import { getFirebase } from "../services/firebaseService";
import firebase from "firebase/app";
import "firebase/database";
import { Entity } from "../../models/entity";
import { v4 as uuid } from 'uuid';

export class Repository<T extends Entity> {
    directory?: firebase.database.Reference;

    constructor(directory: string) {
        getFirebase().then(app => {
            this.directory = app.database().ref(directory);
        });
    }

    public async add(entity: T): Promise<T> {
        const id = uuid();
        const entityReference = this.getEntityDirectory().child(id);
        await entityReference.set(this.getEntityObject(entity));
        entity.id = id;

        return entity;
    }

    public async update(entity: T): Promise<T> {
        const entityReference = this.getEntityDirectory().child(entity.id as string);
        await entityReference.update(this.getEntityObject(entity));

        return entity;
    }

    public async updateSeveral(entities: Array<T>): Promise<void> {
        for (const entity of entities) {
            await this.update(entity);
        }
    }

    public delete(entity: T): Promise<void> {
        const entityReference = this.getEntityDirectory().child(entity.id as string);

        return entityReference.remove();
    }

    public async list(): Promise<Array<T>> {
        const entities: Array<T> = [];
        const snapshot = (await this.getEntityDirectory().get()).val();

        if (!snapshot) {
            return [];
        }

        for (const id of Object.keys(snapshot)) {
            entities.push({
                id: id,
                ...snapshot[id],
            });
        }

        return entities;
    }

    private getEntityDirectory(): firebase.database.Reference {
        // @ts-ignore
        return this.directory;
    }

    private getEntityObject(entity: T): T {
        const obj = { ...entity };
        // @ts-ignore
        delete obj.id;

        return obj;
    }
}